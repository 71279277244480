import VisibleMixin from '../visible_properties_mixin'
export default {
  mixins: [VisibleMixin],
  inject: {
    isEditor: {
      default: () => false
    }
  },
  props: {
    value: {
      frozen: true
    },
    guid: {
      type: String,
      frozen: true
    },
    size: {
      type: String,
      description: 'Размер',
      editor: 'Size'
    },
    align: {
      type: String,
      description: 'Выравнивание'
    },
    margin: {
      type: String,
      description: 'Отступы',
      default: '5px 10px'
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    block: {
      type: Boolean,
      description: 'Во всю строку',
      default: true
    },
    wrapper: {
      type: Boolean,
      description: 'Блок',
      default: false
    },
    isShow: {
      description: 'Скрыть',
      type: Boolean,
      frozen: true
    },
    pluginName: {
      type: String,
      editor: 'Plugin',
      frozen: true
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно',
      default: false
    },
    required: {
      type: Boolean,
      description: 'Обязательное',
      frozen: true
    },
    isRequired: {
      type: Object,
      editor: 'Conditions',
      options: { label: 'Обязательное' },
      default: () => {
        return {
          type: 'never',
          condition_type: 'and',
          conditions: []
        }
      }
    },
    name: {
      type: String,
      editor: 'TreeForAttr',
      description: 'Атрибут',
      options: {
        removeSpaces: true
      }
    },
    isHiddenFromBlock: {
      type: Boolean,
      default: false,
      frozen: true
    }
  },
  computed: {
    _isRequired () {
      if (this.isHidden || this.isHiddenFromBlock) {
        return false
      }
      return this.checkConditions(this.isRequired)
    }
  },
  mounted () {
    if (this.isShow) {
      this.$set(this.hiddenCondition, 'type', 'always')
    }
    if (this.required) {
      // Переключить режим блокирования на "всегда"
      this.$set(this.isRequired, 'type', 'always')
      // Убрать галочку с поля @TODO Убрать после замены required на isRequired
      this.$emit('change-property', { name: 'required', value: false })
    }
    if (this.pluginName && this.editorChart.type !== 'extend_object' && !this.editorChart.plugin) {
      this.$set(this.editorChart, 'plugin', this.pluginName)
    }
  }
}
